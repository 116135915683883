import {Inject, Injectable, OnDestroy} from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {
    APIService, elStatus, elSubStatus, ModelCourseAssetsFilterInput, ModelCourseSubsFilterInput, ModelSortDirection
} from '../../API.service';
import {getDeepFromObject} from '../auth/helpers';
import AWSConfig from './../../../aws-exports';
import * as S3 from 'aws-sdk/clients/s3';
import {CATALOG_MANAGER_OPTIONS} from './course-catalog.options';
import {FuseProgressBarService} from '../../../@fuse/components/progress-bar/progress-bar.service';
import {Logger} from '@aws-amplify/core';
import {APICustomService} from '../../API.customize.service';


@Injectable()
export class CourseCatalogItemService implements Resolve<any>, OnDestroy
{

    private logger = new Logger('Item Catalog Service');

    routeParams: any;
    course: any;
    courseSubs: any;

    private onCourseChanged = new BehaviorSubject<any>(null);
    onCourseChanged$ = this.onCourseChanged.asObservable();

    private onCourseSubs = new BehaviorSubject<any>(null);
    onCourseSubs$ = this.onCourseSubs.asObservable();

    bucket: any;
    bucketTarget: any;
    bucketTranscoded: any;

    private _unsubscribeAll: Subject<any>;
    /**
     * Constructor
     *
     * @param options
     * @param api
     * @param customApi
     * @param courseResources
     * @param _fuseProgressBarService
     */
    constructor(
        @Inject(CATALOG_MANAGER_OPTIONS) protected options = {},
        private api: APIService,
        private customApi: APICustomService,
        private _fuseProgressBarService: FuseProgressBarService,
    ) {
        // Set the defaults
        this.bucket = AWSConfig['aws_user_files_s3_bucket'];
        this.bucketTarget = this.getConfigValue('S3BucketName');
        this.bucketTranscoded = this.getConfigValue('S3BucketTranscoder');

    }

    getCourseId(): string {
      return this.course.id !== null ? this.course.id : this.routeParams.id;
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        const courseIdVal = this.routeParams.id;
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getCourse(courseIdVal),
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }
    /**
     * Get course
     *
     * @returns {Promise<any>}
     */

    refresh(courseId: string) {
      of(this.getCourse(courseId));
    }

    getCourse(courseId?: string): Promise<any> {
        const courseIdVal = courseId !== null ? courseId : this.routeParams.id;
        return new Promise((resolve, reject) => {
            this.api.GetCourse(courseIdVal)
                .then((result) => {
                    this.course = result;
                    const filter : ModelCourseAssetsFilterInput = {
                        courseId: {eq: this.course.id},
                        or: [
                            { status: {eq: elStatus.active}},
                            { status: {eq: elStatus.new}}]
                    }
                    return this.customApi.ListCourseAssetss(
                        filter,
                        99
                    );
                }).then((resultAssets) => {
                    this.course.assets = resultAssets;
                    this.onCourseChanged.next(this.course);
                    resolve(this.course);
                })
                .catch((err) => reject(err));
        });
    }

    private getUserSubs(courseId: string, userId: string): Promise<any> {
        const filter: ModelCourseSubsFilterInput = {
            status: { eq : elSubStatus.Enrolled},
            or: [
                { status: { eq : elSubStatus.Completed} },
                { status: { eq : elSubStatus.Progress} }
            ]
        };
        return new Promise((resolve, reject) => {
            this.api.SubsCourseByIds(
                userId,
                { eq : courseId},
                ModelSortDirection.DESC,
                filter,
                1
            ).then((result) => {
                resolve(result.items);
            }).catch((err) => {
                resolve(false);
            });
        });
    }

    subsToUser(courseId?: string): Promise<any> {
      const courseIdVal = courseId !== null ? courseId : this.routeParams.id;
      return new Promise((resolve, reject) => {
        this.api.CreateCourseSubs(courseIdVal)
          .then((result) => {
            this.courseSubs = result;
            this.onCourseSubs.next(this.courseSubs);
            resolve(this.courseSubs);
          })
          .catch((err) => reject(err));
      });
    }

    syncS3(): any {
        return new S3(
            {
                accessKeyId: this.getConfigValue('accessKeyId'),
                secretAccessKey: this.getConfigValue('secretAccessKey'),
                region: this.getConfigValue('region')
            });
    }

    getPresignedOverViewFile(media: any): Promise<any> {

        return new Promise((resolve, reject) => {
            if (media && media.sourceMedia && media.sourceMedia.length > 10) {
                const params = {Bucket: '', Key: '', Expires: 3600};
                const newConn = this.syncS3();
                params.Bucket = this.bucketTranscoded;
                params.Key = `${media.sourceMedia}-web-720p.mp4`;
                console.log(params)

                newConn.getSignedUrl('getObject', params, (err, urlLink) => {
                    if (err) {
                        console.log(err);
                        this.catchError({type: 'error', msg: err.message});
                        resolve('assets/images/backgrounds/blue-background.jpg');
                    } else {
                        resolve(urlLink);
                    }
                });
            } else {
                resolve('assets/images/backgrounds/blue-background.jpg');
            }
        });
    }

    getPresignedFile(media: any): Promise<any> {

        return new Promise((resolve, reject) => {
            if (media && media.overviewSourceMedia && media.overviewSourceMedia.length > 10) {
                const params = {Bucket: '', Key: '', Expires: 3600};
                const newConn = this.syncS3();
                params.Bucket = this.bucketTranscoded;
                params.Key = `${media.overviewSourceMedia}-web-720p.mp4`;
                newConn.getSignedUrl('getObject', params, (err, urlLink) => {
                    if (err) {
                        this.catchError({type: 'error', msg: err.message});
                        resolve('assets/images/backgrounds/blue-background.jpg');
                    } else {
                        resolve(urlLink);
                    }
                });
            } else {
                resolve('assets/images/backgrounds/blue-background.jpg');
            }
        });
    }

    getPresignedPoster(media: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (media && media.overviewSourceMedia && media.overviewSourceMedia.length > 10) {
                const params = {
                    Bucket:  this.bucketTranscoded,
                    Key: `${media.overviewSourceMedia}thumbs-720p-00001.png`,
                    Expires: 3600
                };
                const newConn = this.syncS3();
                newConn.getSignedUrl( 'getObject', params, (err, urlLink) => {
                    if (err) {
                        resolve('assets/images/courses/course-image-placeholder.png');
                    } else {
                        resolve(urlLink);
                    }
                });
            } else {
                resolve('assets/images/courses/course-image-placeholder.png');
            }
        });
    }

    getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }

    getStudentsSubs(courseId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const filter : ModelCourseSubsFilterInput = {
                courseId: {eq: courseId }
            }
            this.api.ListCourseSubss(
                filter,
                9999
            ).then((result) => {
                resolve(result.items.length);
            }).catch((err) => {
                resolve(false);
            });
        });
    }

    private catchError(error) {
        console.log(error);
        this.logger.debug('OOPS!', error);
        this._fuseProgressBarService.hide();
    }
}
