import {Inject, Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {ACADEMY_MANAGER_OPTIONS} from './academy.options';
import {
    APIService, elStatus, ModelCourseAssetsFilterInput, ModelCourseAssetSubsFilterInput,
} from '../../API.service';
import {getDeepFromObject} from '../auth/helpers';
import {Logger} from 'aws-amplify';
import {AuthService} from '../auth/services/auth.service';
import {FuseProgressBarService} from '../../../@fuse/components/progress-bar/progress-bar.service';
import {CourseAsset} from './media.model';

@Injectable()
export class AcademyCourseService implements Resolve<any> {
    private logger = new Logger('Course Academy Service');

    routeParams: any;
    course: any;
    content: CourseAsset;

    pageSize: number;
    nextToken: string = null;

    private onCourseChanged = new BehaviorSubject<any>(null);
    onCourseChanged$ = this.onCourseChanged.asObservable();

    private nextPageToken = new BehaviorSubject<any>(this.nextToken);
    nextPageToken$ = this.nextPageToken.asObservable();

    bucket: string;
    /**
     * Constructor
     *
     * @param options
     * @param api
     * @param authService
     * @param _fuseProgressBarService
     */
    constructor(
        @Inject(ACADEMY_MANAGER_OPTIONS) protected options = {},
        private api: APIService,
        private authService: AuthService,
        private _fuseProgressBarService: FuseProgressBarService,
    )
    {
        this.pageSize = this.getConfigValue('pageSize');
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getCourse(route.params.courseId)
            ]).then((values) => {
                if (values[0].course.assets.items
                    && values[0].course.assets.items.length > 0) {
                        resolve(true);
                    } else {
                        reject(null);
                    }
                },
                reject
            );
        });
    }

    /**
     * Get course
     *
     * @param courseId
     * @returns {Promise<any>}
     */
    getCourse(courseId: string): Promise<any> {
        this._fuseProgressBarService.show();
        const userInfo = this.authService.getUserId();
        const userId = userInfo.getUsername();
        const courseIdVal = courseId !== null ? courseId : this.routeParams.courseId;
        return new Promise((resolve, reject) => {
            this.api.GetCourseSubs(courseIdVal)
                .then(result => {
                    this.course = result;
                    const filterSubs: ModelCourseAssetSubsFilterInput = {
                        courseSubsId: {eq: result.courseId},
                        userId: {eq: userId}
                      };
                    return this.api.ListCourseAssetSubss(filterSubs, 99);
                }).then(resultCourseAssets => {
                    this.course.assetsSubs = resultCourseAssets;
                    const filterAssets: ModelCourseAssetsFilterInput = {
                        courseId: {eq: this.course.courseId},
                        or: [
                            { status: {eq: elStatus.active}},
                            { status: {eq: elStatus.new}}]
                    };
                    return this.api.ListCourseAssetss(filterAssets, 99);
                }).then(assets => {
                    this.course.course.assets = assets;
                    this.onCourseChanged.next(this.course);
                    this._fuseProgressBarService.hide();
                    resolve(this.course);
                }).catch(err => {
                    this.catchError(err);
                    reject(err);
                });
        });
    }

    private catchError(error) {
      console.log(error);
      this.logger.debug('OOPS!', error);
      this._fuseProgressBarService.hide();
    }


    getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }

}
