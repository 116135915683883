import {FuseNavigation} from '@fuse/types';

export const NOUSER: FuseNavigation[] = [
    {
        id       : 'meNoUser',
        title    : 'Me',
        permission: ['USERS', 'ADMIN'],
        translate: 'NAV.ME',
        type     : 'item',
        icon     : 'assignment_ind',
        url      : 'me'
    }
];

export const ADMINNAV: FuseNavigation[] = [
  {
    id       : 'applications',
    title    : 'Applications',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    permission: ['USERS', 'ADMIN'],
    children : [
      {
        id       : 'academy',
        title    : 'My Courses',
        permission: ['USERS', 'ADMIN'],
        translate: 'NAV.ACADEMY',
        type     : 'item',
        icon     : 'school',
        url      : '/my/courses'
      },
      {
        id       : 'catalog',
        title    : 'Courses',
        permission: ['USERS', 'ADMIN'],
        translate: 'NAV.CATALOG',
        type     : 'item',
        icon     : 'local_convenience_store',
        url      : '/school/courses'
      }
    ]
  }
];

export const USERSNAV: FuseNavigation[] = [
  {
    id       : 'applications',
    title    : 'Me',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    permission: ['USERS', 'ADMIN'],
    children : [

      {
        id       : 'academy',
        title    : 'Dashboard',
        permission: ['USERS', 'ADMIN'],
        translate: 'NAV.ACADEMY',
        type     : 'item',
        icon     : 'school',
        url      : '/my/courses'
      },
      {
        id       : 'catalog',
        title    : 'Academy',
        permission: ['USERS', 'ADMIN'],
        translate: 'NAV.CATALOG',
        type     : 'item',
        icon     : 'local_convenience_store',
        url      : '/school/courses'
      }
    ]
  },
];




