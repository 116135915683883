import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { FuseSharedModule } from '@fuse/shared.module';

import { QuickPanelComponent } from 'app/layout/components/quick-panel/quick-panel.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatButtonModule, MatCardModule, MatFormFieldModule, MatOptionModule, MatSelectModule} from '@angular/material';
// import {PushNotifyModule} from '../../../main/admin/push-notify/push-notify.module';
import {NotificationItemComponent} from './notification-item/notification-item.component';
import {RouterModule} from '@angular/router';
import {SyncSettings} from '../../../../@fuse/services/sync-settings.service';
import {MomentAgoPipe} from '../../../core/pipes/moment-ago.pipe';

@NgModule({
    declarations: [
        QuickPanelComponent,
        NotificationItemComponent,
        MomentAgoPipe
    ],
    imports: [
        MatDividerModule,
        MatListModule,
        MatSlideToggleModule,
        FuseSharedModule,
        MatTabsModule,
        MatIconModule,
        MatSidenavModule,
        MatCardModule,
        MatFormFieldModule,
        MatButtonModule,
        MatOptionModule,
        MatSelectModule,
        // PushNotifyModule,
        RouterModule,
    ],
    exports: [
        QuickPanelComponent
    ],
    providers: [SyncSettings]
})
export class QuickPanelModule
{
}
