import {Inject, Injectable} from '@angular/core';

import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {CognitoUser} from 'amazon-cognito-identity-js';
import Auth, {CognitoHostedUIIdentityProvider} from '@aws-amplify/auth';
import {Hub, ICredentials} from '@aws-amplify/core';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {FuseProgressBarService} from '../../../../@fuse/components/progress-bar/progress-bar.service';
import {Router} from '@angular/router';
import {Analytics, API, Logger} from 'aws-amplify';
import {DataStore} from '@aws-amplify/datastore';
import * as AWS from 'aws-sdk';
import {getDeepFromObject} from '../helpers';
import {FuseNavigationService} from '../../../../@fuse/components/navigation/navigation.service';
import {APIService, ModelSortDirection} from '../../../API.service';
import {UserSettingsModel} from '../../../user-settings.model';
import * as _ from 'lodash';
import {USER_MANAGER_OPTIONS} from '../users.options';

export interface NewUser {
    email: string;
    phone: string;
    password: string;
    familyName: string;
    givenName: string;
    termsAccepted: boolean;
}

const STORAGE_KEY = 'AUTH_USR';
const ANALYTICS_TOKEN = 'AUTH';


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public static FACEBOOK = CognitoHostedUIIdentityProvider.Facebook;
    public static GOOGLE = CognitoHostedUIIdentityProvider.Google;
    public static SIGN_IN = 'signIn';
    public static SIGN_OUT = 'signOut';
    public loggedIn: boolean;
    private groups: any[];
    private groupAssigned: string;
    private userINFO: CognitoUser;
    private logger = new Logger('AuthService');
    private userSettings: UserSettingsModel = new UserSettingsModel();

    public _authState: BehaviorSubject<CognitoUser|any> = new BehaviorSubject({} );
    authState: Observable<CognitoUser|any> = this._authState.asObservable();

    private loggedUser: BehaviorSubject<CognitoUser|any> = new BehaviorSubject({} );
    loggedUser$: Observable<CognitoUser|any> = this.loggedUser.asObservable();

    public isLogin: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isLogin$: Observable<boolean> = this.isLogin.asObservable();

    public allowStorage: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public allowStorage$: Observable<boolean> = this.allowStorage.asObservable();

    public userGroups: BehaviorSubject<any[]> = new BehaviorSubject([] );
    userGroups$: Observable<any[]> = this.userGroups.asObservable();

    public groupAllowed: BehaviorSubject<any> = new BehaviorSubject('NOUSER' );
    groupAllowed$: Observable<any[]> = this.groupAllowed.asObservable();

    private authStateFlow: Subject<CognitoUser|any> = new Subject<CognitoUser|any>();
    authStateFlow$: Observable<CognitoUser|any> = this.authStateFlow.asObservable();

    private onSettingsChanged: BehaviorSubject<any> = new BehaviorSubject(this.userSettings);
    public onSettingsChanged$ = this.onSettingsChanged.asObservable();

    constructor(
        @Inject(USER_MANAGER_OPTIONS) protected options = {},
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _fuseNavigationService: FuseNavigationService,
        private api: APIService,
        private _router: Router
    ) {
        console.log('Service is On...');
        this.isLogin.next(false);
        this.loggedIn = false;
        this._authState.next( {}  );
        Hub.listen('auth', ({ payload: { event, data } }) => {
              // console.log('---listen  --->', event);
              // console.log('---listen  --->', data);
              switch (event) {
                case 'signIn':
                    this.userINFO = data;
                    this.loggedUser.next(data);
                    break;
                case 'signOut':
                    this.loggedUser.next(data);
                    break;
                case 'customOAuthState':
                    this.userINFO = data;
                    this.loggedUser.next(data);
            }
        });

        Hub.listen('core', (data) => {
            this.logger.info('core' , data);
            // console.log(data);
        });
        // Hub.listen('api', (data) => {
        //     console.log('--api---->');
        //     console.log(data);
        // });
        Hub.listen('interactions', (data) => {
            this.logger.info('interaction' , data);
        });

        // of(this.checkSession());
    }

    // bypassCache: Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    checkSession(): Promise<boolean> {
        console.log('--- checkin session ----');
        return new Promise((resolve, reject) => {
            Auth.currentAuthenticatedUser()
                .then((userInfo: CognitoUser | any) => {
                this._authState.next(userInfo);
                this.userINFO = userInfo;
                this.loggedUser.next(userInfo);
                this.isLogin.next(true);
                this.loggedIn = true;
                return userInfo.getUsername();
            }).then((userName: string) => {
                return this.getListGroupsForUser(userName);
            }).then((groups: any) => {
                console.log('------ session Step 1 ----');
                this.groups = groups.Groups;
                this.userGroups.next(this.groups);
                return this.groups;
            }).then((groups: any) => {
                return this.setNavigationOptions(groups);
            }).then((navGroup: any) => {
                this.groupAssigned = navGroup;
                this.groupAllowed.next(navGroup);
                this._fuseNavigationService.setCurrentNavigation(navGroup);
                const userName = this.userINFO.getUsername();
                return this.api.SettingsByUserId(userName, ModelSortDirection.DESC, null, 1);
            }).then((settings: any) => {
                console.log('------ session Step 2 ----');
                if (_.isArray(settings.items) && settings.items.length > 0) {
                    const currentSetts = settings.items[0];
                    this.userSettings  = new UserSettingsModel(currentSetts);
                } else {
                    this.userSettings = new UserSettingsModel();
                }
                this.onSettingsChanged.next(this.userSettings);
                console.log('------ session ok ----');
                resolve(true);
            }).catch((error: any) => {
                this.userINFO = null;
                this.loggedUser.next(null);
                this._authState.next(null);
                this.isLogin.next(false);
                this.groups = [];
                this.userGroups.next(this.groups);
                this.loggedIn = false;
                this.userSettings = new UserSettingsModel();
                this.onSettingsChanged.next(this.userSettings);
                this.catchError(error, 'CheckSession');
                resolve(false);
            });
        });
    }

    async currentUser(): Promise<CognitoUser|any>  {
        return await Auth.currentAuthenticatedUser({
                bypassCache: true
            });
    }

    getUserSettings() {
        return this.userSettings;
    }

    getUserId(): CognitoUser|any {
      return this.userINFO;
    }

    setNavigationOptions(groups: any[]): Promise<string>  {
      return new Promise((resolve, reject) => {
          if (!groups || groups.length === 0 ) {
              resolve('NOUSER');
          }
          const foundAdmin = groups.filter(x => x.GroupName === 'ADMIN');
          if (foundAdmin.length === 1){
              resolve('ADMIN');
          }
          const foundUser = groups.filter(x => x.GroupName === 'USERS');
          if (foundUser.length === 1){
              resolve('USERS');
          } else {
              resolve('USERS');
          }
      });
    }

    isRouteAuthorized(urlActive: string): boolean {
        const currNav = this._fuseNavigationService.getCurrentNavigation();
        const flatNav = this._fuseNavigationService.getFlatNavigation(currNav);
        const foundRoute = flatNav.filter(x => urlActive.includes(x.url));
        if (foundRoute && foundRoute.length >= 1) {
            return true;
        } else if (urlActive === '/me') {
            return  true;
        } else if (urlActive.search('/admin/content') >= 0) {
            return  true;
        } else if (urlActive.search('/admin/quiz') >= 0){
            return true;
        } else if (urlActive.search('/admin/contacts') >= 0){
            return true;
        } else if (urlActive.search('/admin/company') >= 0){
            return true;
        } else if (urlActive.search('/coach/students') >= 0){
            return true;
        }
        return false;
    }

    getUserAllowed(): any {
      return this.groupAssigned;
    }


    getUserGroups(): any[] {
      return this.groups;
    }

    syncCognito(): any {
      return new AWS.CognitoIdentityServiceProvider(
        {
          apiVersion: '2016-04-18',
          region: this.getConfigValue('region'),
          accessKeyId: this.getConfigValue('accessKeyId'),
          secretAccessKey: this.getConfigValue('secretAccessKey')
        }
      );
    }

     getListGroupsForUser( username?: string): Promise<any> {
      return new Promise((resolve, reject) => {
        const conn = this.syncCognito();
        const params = {
          UserPoolId: this.getConfigValue('UserPoolId'),
          Username: username
        };
        conn.adminListGroupsForUser(params, (err, data) => {
          if (err) {
            this.catchError(err, 'getListGroupsForUser');
            resolve([]);
          }
          resolve(data);
        });
      });
    }

    public check(responseToken: any): any {
      return new Promise((resolve, reject) => {
        const body = {
          body: {
            rToken: responseToken
          }
        };
        API.post('coreApiKTC', '/checktoken', body)
          .then(result => {
            resolve(result);
          }).catch((error: any) => {
            this.catchError(error, 'checkToken');
            reject(error.message);
        });
      });
    }

    login(data?: any, responseToken?: any): Promise<CognitoUser|any> {
        return new Promise((resolve, reject) => {
          const body = {
            body: {
              rToken: responseToken
            }
          };
          API.post('coreApiKTC', '/checktoken', body)
            .then(result => {
                this.storage.remove(STORAGE_KEY);
                return Auth.signIn(data.email, data.password);
            }).then((user: CognitoUser|any) => {
              // if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              //     resolve(user);
              // } else {
                  this._authState.next( user  );
                  this.isLogin.next(true);
                  this.setUser(STORAGE_KEY, user);
                  this.loggedIn = true;
                  resolve(user);
              // }
            }).catch((error: any) => {
              this.catchError(error, 'login');
              reject(error);
          });
        });
    }

    register(user: NewUser, responseToken?: any): Promise<CognitoUser|any> {
        return new Promise((resolve, reject) => {
            const body = {
              body: {
                rToken: responseToken
              }
            };
            API.post('coreApiKTC', '/checktoken', body)
              .then(result => {
                  const newUser = {
                      username: user.email,
                      password : user.password,
                      attributes: {
                          name :  `${user.givenName} ${user.familyName}`,
                          email: user.email,
                          given_name: user.givenName,
                          family_name: user.familyName,
                          phone_number: ''
                      }};
                  return Auth.signUp(newUser);
            }).then((userData: CognitoUser|any) => {
                resolve(userData);
            }).catch((error: any) => {
              this.catchError(error, 'register');
              reject(error);
            });
        });
    }

    resetPassword(data?: any, responseToken?: any): Promise<CognitoUser|any> {
      return new Promise((resolve, reject) => {
        const body = {
          body: {
            rToken: responseToken
          }
        };
        API.post('coreApiKTC', '/checktoken', body)
          .then(result => {
              return Auth.forgotPassword(data.email);
          }).then((userData: CognitoUser|any) => {
              userData.email = data.email;
              resolve(userData);
          }).catch((error: any) => {
            this.catchError(error, 'resetPassword');
            reject(error);
        });
      });
    }

    logout(): Promise<any> {
      return Auth.signOut()
          .then(() => {
              this.storage.clear();
              DataStore.clear();
              this._authState.next(null);
              this.isLogin.next(false);
              this.loggedUser.next(null);
              this.loggedIn = false;
          });
    }

    socialSignIn(provider: CognitoHostedUIIdentityProvider): Promise<ICredentials> {
        return Auth.federatedSignIn({
            provider: provider
        });
    }

    requestPassword(user: NewUser, oldPass: string, newPass: string): Promise<any>{
        return Auth.changePassword(user,
            oldPass,
            newPass
            );
    }

    confirmPasswordSubmit(data?: any): Promise<void> {
        return Auth.forgotPasswordSubmit(data.email,
            data.code,
            data.password);
    }

    resendSignUp(data?: any): Promise<any> {
        return Auth.resendSignUp(data.email);
    }

    confirmSignUp(data?: any): Promise<any> {
        return Auth.confirmSignUp(data.email, data.code);
    }

    confirmNewPassword(data: any, newUser: CognitoUser | any): Promise<any> {
        return Auth.completeNewPassword(newUser, data.newpassword, []);
    }

    changePassword(oldPassword: string, newPassword: string): Promise<any> {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            Auth.currentAuthenticatedUser()
                .then(currentUser => {
                    console.log(currentUser);
                    return Auth.changePassword(currentUser, oldPassword, newPassword);
                }).then((results: any) => {
                    this._fuseProgressBarService.hide();
                    resolve(results);
                }).catch((error: any) => {
                this._fuseProgressBarService.hide();
                reject(error.message);
            });
        });
    }

    getUser(sKey: string): CognitoUser|any {
        return this.storage.get(sKey) !== undefined ? JSON.parse(this.storage.get(sKey)) : {};
    }

    getUserToConfirm(sKey: string): CognitoUser|any {
        return this.storage.get(sKey) !== undefined ? JSON.parse(this.storage.get(sKey)) : {};
    }

    setUser(sKey: string, user: CognitoUser|any): void{
        this.storage.set(sKey, JSON.stringify(user));
    }

    removeKey(sKey: string): void{
        this.storage.remove(sKey);
    }

    public saveAnalytics(event: string, withCoords: boolean) {
      Analytics.record({
          name: ANALYTICS_TOKEN,
          attributes: {
              event: event,

          }
      });
    }

    private verifyLogin(): boolean{
        const user: CognitoUser|any = this.getUser(STORAGE_KEY);
        return !!user.id;
    }

    // Functions
    getConfigValue(key: string): any {
      return getDeepFromObject(this.options, key, null);
    }

    private catchError(error, key) {
      this.logger.debug(key , error);
    }
}
